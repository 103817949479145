@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overscroll-behavior: none;
  background: linear-gradient(to bottom, #363636, #000000); /* Replace with your desired colors */
  color: #a0a0a0;
  font-family: 'Inter var', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

input[type="password"] {
    /* Add your styles here */
    padding: 10px;
    font-size: 24px;
    border-radius: 20px;
    border: none;
    background-color: black;
    color: #a0a0a0; /* Replace with the color of the rest of your text */
    box-shadow: 0 0 10px #a0a0a0; /* This creates the outer glow */
  }
  
  button {
    /* Add your styles here */
    border: none;
    background-color: #000000; /* Replace with your desired color */
    color: #a0a0a0;
    border-radius: 15px;
    font-size: 13px;

    line-height: 0.7em;
    margin: 1rem;
    padding: 10px 20px;
  }

h1 {
  font-size: 30vw;
  font-weight: 400;
  letter-spacing: -0.05em;
  line-height: 0.7em;
  margin: 0;
  padding: 0;
}

a {
  pointer-events: all;
  color: #a0a0a0;
  text-decoration: none;
}

svg {
  fill: #a0a0a0;
}

@keyframes wiggle {
  0%, 10%, 20%, 30%, 40%, 50%, 60% { transform: rotate(0deg); }
  70% { transform: rotate(-10deg); }
  72% { transform: rotate(10deg); }
  74% { transform: rotate(-10deg); }
  76% { transform: rotate(10deg); }
  78% { transform: rotate(-10deg); }
  80% { transform: rotate(10deg); }
  82% { transform: rotate(-10deg); }
  84% { transform: rotate(10deg); }
  86% { transform: rotate(-10deg); }
  88% { transform: rotate(10deg); }
  90% { transform: rotate(-10deg); }
  92% { transform: rotate(10deg); }
  94% { transform: rotate(-10deg); }
  96% { transform: rotate(10deg); }
  98% { transform: rotate(-10deg); }
  100% { transform: rotate(0deg); }
}


.wiggle {
  animation: wiggle 5s infinite;
}